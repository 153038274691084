import Banner from "@/components/Cliente/Banner/Banner.vue";
import {
	required,
	minLength,
	email
} from "vuelidate/lib/validators";
import mSwitch from "@/components/Switch/Switch.vue";
import jsPDF from 'jspdf/dist/jspdf.debug';
import html2canvas from "html2canvas";
import Axios from "axios";

export default {
	components: {
		Banner,
		mSwitch,
	},
	data() {
		return {
			blEmailSucesso: false,
			blEmailSucessoBranco: false,
			blLoadingEnviarEmail: false,
			intEtapa: 1,
			blMostrar: false,
			blMostrarPdfBranco: false,
			blDialog: false,
			blDialogEmail: false,
			blLoading: true,
			blLoader: false,
			blLoader1: false,
			blErro: false,
			snackbar: false,
			arPerfil: [],
			perfilSelectionados: "",
			arPerguntas: null,
			arPerguntasBranco: null,
			arCanvasDataURL: [],
			strModalInfoAdicional: "",
			strTitulo: "<span class='nome'>Check List</span>",
			strTipoEmail: "",
			valid: true,
			email: "",
			corpo: "",
			data: new Date().toISOString().substr(0, 10),
			obForm: {
				id: 0,
			}
		};
	},

	validations: {
		obForm: {
			id: {
				peloMenosUm: function (value) {
					if (!value || value.length == 0)
						return false;

					return true;
				},
				menorQueTres: function (value) {
					if (value.length > 3)
						return false;

					return true;
				}
			}
		},
	},

	created() {
		this.listarPerfis()
	},


	methods: {
		parseDate() {
			if (!this.data) return null;

			const [year, month, day] = this.data.split("-");
			return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
		},
		limiter(e) {
			if (e.length > 3) {
				e.pop()
			}
		},
		changeToStep(step) {
			if (this.intEtapa == 1) {
				return;
			} else {
				this.intEtapa = step;
			}
		},
		listarPerfis() {
			this.$root.$api.get("checklist/listarPerfis").then(response => {
				this.arPerfil = response.retorno
			});
		},

		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}

		},

		eventoInformacaoAdicional(informacao) {
			this.strModalInfoAdicional = informacao
			this.blDialog = true
		},

		submit() {
			this.$v.obForm.$touch();
			this.validarFormulario();

			if (this.blErro) {
				return false;
			}
			this.perfilSelectionados = "";
			this.obForm.id.forEach((i) => {
				this.perfilSelectionados += this.arPerfil[i - 1].nome + ", ";
			});
			this.perfilSelectionados = this.perfilSelectionados.substring(0, this.perfilSelectionados.length - 2);

			this.$root.$api.post('checklist/obterPorPerfil', {
				perfis: this.obForm.id
			}).then(
				response => {
					this.arPerguntas = response.retorno
					this.arPerguntasBranco = response.retorno
					this.blLoading = false
				}
			)
			this.intEtapa = 2
		},

		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
		},

		mensagemErroPerfis() {
			if (!this.$v.obForm.id.$dirty)
				return [];
			if (!this.$v.obForm.id.peloMenosUm)
				return ['Selecione um perfil'];
			if (!this.$v.obForm.id.menorQueTres)
				return ['Selecione no máximo 3 perfis'];

			return [];
		},

		html2canvas(div) {
			var that = this
			let y = document.getElementsByClassName("imgPasso3")[0].getBoundingClientRect().height;
			setTimeout(function () {
				let imagemTela = document.getElementById(div);
				console.log(imagemTela.scrollWidth,' // ',imagemTela.scrollHeight);
				// html2canvas(imagemTela).then(function (canvas) {
				html2canvas(imagemTela, {
					width: 1100,//(imagemTela.scrollWidth  + 100) > 3720 ? 3720 :(imagemTela.scrollWidth  + 100),
					height: imagemTela.scrollHeight + 500,
					windowWidth: 1100,//(imagemTela.scrollWidth  + 100) > 3720 ? 3720 :(imagemTela.scrollWidth  + 100),
					windowHeight: imagemTela.scrollHeight + 500,
					scrollX: 0,
					scrollY: -window.scrollY,
					x: 150,
					y: y,
					scale:1
				}).then(canvas => {
					var pdf = new jsPDF('p', 'pt', 'a4',true);
					for (var i = 0; i <= imagemTela.clientHeight / 980; i++) {   
						var srcImg = canvas;
						var sX = 0;
						var sY = 1120 * i;
						var sWidth = 900;
						var sHeight = 1120;
						var dX = 0;
						var dY = 0;
						var dWidth = 900;
						var dHeight = 1120;
						let onePageCanvas = document.createElement("canvas");
						onePageCanvas.setAttribute('width', 900);
						onePageCanvas.setAttribute('height', 1120);
						var ctx = onePageCanvas.getContext('2d');
						ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
						var canvasDataURL = onePageCanvas.toDataURL("image/png", 0.1);
						var width = onePageCanvas.width;
						var height = onePageCanvas.clientHeight;

						if (i > 0) {
							pdf.addPage(595, 842);
						}

						pdf.setPage(i + 1);
						that.arCanvasDataURL = canvasDataURL
						pdf.addImage(canvasDataURL, 'PNG', 20, 20, (width * .63), (height * .71),'','FAST');

					}
					var d = new Date().toLocaleDateString().replace(/\//g, '-');
					var fileName = 'Checklist_gerado_' + d + '.pdf';
					if (div === 'pdfBranco') {
						fileName = "checklist_" + d + '.pdf';
					}
					pdf.save(fileName);

					if (div === 'pdf') {
						that.blLoader = false
						that.blMostrar = false
					} else {
						that.blLoader1 = false
						that.blMostrarPdfBranco = false
					}
				
				});
			}, 500);
		},

		gerarPDF(div) {
			if (div === 'pdf') {
				this.blMostrar = true
				this.blLoader = true
				this.html2canvas('pdf')
				// this.montarPdf('pdf');
			} else {
				this.blMostrarPdfBranco = true
				this.blLoader1 = true
				this.html2canvas('pdfBranco')
			}
		},
		imprimir(pdf) {
			this.blMostrar = true

			setTimeout(function () {
				var printContents = document.getElementById(pdf).innerHTML
				var originalContents = document.body.innerHTML

				document.body.innerHTML = printContents
				print()
				document.body.innerHTML = originalContents

				document.location.reload(true)

			}, 500);
		},

		loader() {
			const l = this.loader
			this[l] = !this[l]

			setTimeout(() => (this[l] = false), 3000)

			this.loader = null
		},

		enviarEmail() {
			if (this.strTipoEmail === 'pdf') {
				this.blMostrar = true
				this.blEmailSucesso = true
				this.blLoadingEnviarEmail = this.blEmailSucesso
			} else {
				this.blMostrarPdfBranco = true
				this.blEmailSucessoBranco = true
				this.blLoadingEnviarEmail = this.blEmailSucessoBranco
			}

			if (this.blErro === false) {
				var that = this
				let y = document.getElementsByClassName("imgPasso3")[0].getBoundingClientRect().height;
				setTimeout(function () {
					let imagemTela = document.getElementById(that.strTipoEmail);
					console.log(imagemTela.scrollWidth,' // ',imagemTela.scrollHeight);
					// html2canvas(imagemTela).then(function (canvas) {
					html2canvas(imagemTela, {
						width: 1100,//(imagemTela.scrollWidth  + 100) > 3720 ? 3720 :(imagemTela.scrollWidth  + 100),
						height: imagemTela.scrollHeight + 500,
						windowWidth:  1100,//(imagemTela.scrollWidth  + 100) > 3720 ? 3720 :(imagemTela.scrollWidth  + 100),
						windowHeight: imagemTela.scrollHeight + 500,
						scrollX: 0,
						scrollY: -window.scrollY,
						x: 150,
						y: y,
						scale:1
					}).then(canvas => {
						let files = [];
						for (var i = 0; i <= imagemTela.clientHeight / 980; i++) {
							var srcImg = canvas;
							var sX = 0;
							var sY = 1120 * i;
							var sWidth = 900;
							var sHeight = 1120;
							var dX = 0;
							var dY = 0;
							var dWidth = 900;
							var dHeight = 1120;
							let onePageCanvas = document.createElement("canvas");
							onePageCanvas.setAttribute('width', 900);
							onePageCanvas.setAttribute('height', 1120);
							var ctx = onePageCanvas.getContext('2d');
							ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
							var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
							files.push(canvasDataURL);
						}
					that.$root.$api.postUrlCompleta('https://www.econeteditora.com.br/irpf/email.php', {
								email: that.email,
								files: files,
								nome: localStorage.getItem("nome_usuario"),
								corpo: that.corpo
							})
							.then(response => {
								that.snackbar = true;
								that.blDialogEmail = false
								that.email = "";
								that.nome = "";
								if (that.strTipoEmail === 'pdf') {
									that.blMostrar = false
									that.blEmailSucesso = false
									that.blLoadingEnviarEmail = that.blEmailSucesso
								} else {
									that.blMostrarPdfBranco = false
									that.blEmailSucessoBranco = false
									that.blLoadingEnviarEmail = that.blEmailSucessoBranco
								}
							})
					});

				}, 500);
			}
		},


		scrollWin() {
			window.scrollTo(0, 460);
		},

	},

	computed: {
		respondidas() {
			let arRespondidas = this.arPerguntas.filter(obPergunta => {
				return obPergunta.aberto != null
			})

			return arRespondidas.length
		}
	},

};